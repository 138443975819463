/* src/App.css */
.App {
  text-align: center;
}

.image-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-card {
  margin: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.image-card img {
  max-width: 100%;
  height: auto;
}

.image-card h2 {
  font-size: 16px;
  margin: 10px 0;
}

.image-card a {
  text-decoration: none;
  color: #007bff;
}
